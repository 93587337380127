/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import { Layout as Wrapper, StyledButton, ImageWrapper, Formwrapper, BottomWrapper, Languagecontainer, IconContainer } from './styles';
import { ResetPasswordModal, LoginPageV2, RegistrationForm, RegistrationWellnessInterests, RegistrationFitnessGoals, RegistrationGeneralHealth, Layout, LocationDepartment } from '../../components';
import RegistrationLegalUpdates from '../../components/RegistrationLegalUpdates';
import { authenticatedUser, getCompanyId, getSpouseDependentInfo, getSpouseDependentAtCompanyLogin, authenticatedUserEmailToken } from '../../redux/actions';
import { checkPermission } from '../../utils/methods';
import { isUndefined } from 'lodash';
import Waiting from '../../components/Waiting';
import { LOGO_URL } from '../../utils/config';
import moment from 'moment';
import i18n from '../../Lang/i18n';
import { withTranslation } from 'react-i18next';
import LazyImage from '../../components/common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';

class LoginV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: (typeof localStorage !== 'undefined' && localStorage?.getItem('lang')) || 'en' || 'es',
      drop:false
    }
  }


  checkResetPasswordLink(resetPasswordUrl) {
    if (resetPasswordUrl[resetPasswordUrl.length - 1] === '') {
      return resetPasswordUrl[2] !== 'reset' || resetPasswordUrl[resetPasswordUrl.length - 2] !== 'brief' ? false : true;
    }
    else {
      return resetPasswordUrl[2] !== 'reset' || resetPasswordUrl[resetPasswordUrl.length - 1] !== 'brief' ? false : true;
    }
  }

  componentDidMount() {
    const { history, location, token, authenticatedUser, getCompanyId, companyId, getSpouseDependentInfo, getSpouseDependentAtCompanyLogin, authenticatedUserEmailToken } = this.props;
    location && location.search ? authenticatedUserEmailToken(location.search.split('=')[1]) : null;
    i18n.changeLanguage(this.state.lang);
    if (typeof localStorage !== 'undefined') {
      if (checkPermission()) {
        if (!token) authenticatedUser();
        if (!companyId) getCompanyId();
        getSpouseDependentInfo();
        getSpouseDependentAtCompanyLogin();
        const authToken = localStorage.getItem('AUTH_TOKEN');
        const { pathname } = window.location;
        let resetPasswordUrl = pathname.split('/');
        if (companyId < 0) {
          if (!this.checkResetPasswordLink(resetPasswordUrl)) {
            history.push('/user');
          }
        }
        if (token !== '' || authToken) {
          history.push('/portal');
        }
      }
      else {
        alert("Please enable your cookies and localstorage");
        history.push('/');
      }
    }else{
      alert("Please enable your cookies and localstorage");
      history.push('/');
    }
  }

  registrationLegalUpdates = () => {
    const { history } = this.props;
    return (<RegistrationLegalUpdates history={history} />)
  }

  registrationForm = () => {
    const { history, companyId, isSpouseDependent, isSpouse, isDependent, selectedOption } = this.props;
    return (<RegistrationForm companyId={companyId} history={history} isSpouseDependent={isSpouseDependent} isSpouse={isSpouse} isDependent={isDependent} selectedUserType={selectedOption} />)
  }

  registrationWellnessInterests = () => {
    const { history, companyId } = this.props;
    return (<RegistrationWellnessInterests companyId={companyId} history={history} />)
  }

  registrationFitnessGoals = () => {
    const { history, companyId } = this.props;
    return (<RegistrationFitnessGoals companyId={companyId} history={history} />)
  }

  registrationGeneralHealth = () => {
    const { history, companyId } = this.props;
    return (<RegistrationGeneralHealth companyId={companyId} history={history} />)
  }

  registrationLegalUpdatesUserType = () => {
    const { history, userType } = this.props;
    return (<RegistrationLegalUpdates history={history} userType={userType} />)
  }

  locationDepartment = () => {
    const { history, userType } = this.props;
    return (<LocationDepartment history={history} userType={userType} />)
  }

  redirection = () => {
    const { history } = this.props;
    history.push("/")
  }
  handleDropdown=()=>{
    this.setState({drop:!this.state.drop})
  }
  takeLanguageData = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    this.setState({ lang: lang });
    this.handleDropdown()
  };

  render() {
    const { departmentDetails, locationDetails, history, t } = this.props;
    let { lang,drop } = this.state;
    if (isUndefined(locationDetails) || isUndefined(departmentDetails)) {
      return <Waiting />
    }
    const loginPage = history.location.pathname == "/user";
    return (
      <Layout  display={loginPage}>
        <Wrapper display="1" float="1" border="1" background="1" fullwidth={!loginPage}>
          <ImageWrapper display={loginPage}>
            <div className="text">{t("IT'S WORK")}<br />{t("LIFE BALANCE")}</div>
            <LazyImage div={"image"} src={ImageUrl + "/images/Logo/loginBanner.png"} alt="woliba-banner"/>
          </ImageWrapper>
          <Formwrapper fullwidth={!loginPage}>

            <Languagecontainer drop={this.state.drop} fullwidth={!loginPage} >
              <h3>{t("Language")}</h3>
              <h2>Lang</h2>
              <IconContainer 
                active={history.location.pathname.includes('community')} 
                onClick={this.handleDropdown}
              >

                <LazyImage className='lang' src={`${ImageUrl}/images/NewHeader/${lang == 'en' ? "English.png" : lang == 'es' ? "Spanish.png" :lang == 'pt'? "Portuguese.png" : "French.png"}`} />
                <div className='arrowContainer' ><LazyImage className='arrow' src={drop?ImageUrl + "/images/langArrowDown.svg": ImageUrl + '/images/langArrow.svg'}/> </div>
              </IconContainer>
              <ul>
                <li><a onClick={() => this.takeLanguageData("en")}><span><LazyImage src={ImageUrl + "/images/NewHeader/English.png"} /></span>English</a></li>
                <li><a onClick={() => this.takeLanguageData("es")}><span><LazyImage src={ImageUrl + "/images/NewHeader/Spanish.png"} /></span>Spanish</a></li>
                <li><a onClick={() => this.takeLanguageData("fr")}><span><LazyImage src={ImageUrl + "/images/NewHeader/French.png"} /></span>French</a></li>
                <li><a onClick={() => this.takeLanguageData("pt")}><span><LazyImage src={ImageUrl + "/images/NewHeader/Portuguese.png"} /></span>Portuguese</a></li>
              </ul>
            </Languagecontainer>

            {LOGO_URL ?
              <LazyImage div={"logo"} src={ImageUrl + "/images/Logo/Wolibalogo.svg"} alt="woliba-logo" onClick={() => this.redirection()}/> :
              <LazyImage div={"logo"} src={ImageUrl + "/images/Logo/training-amigo-logo-stacked.png"} alt="training-amigo-logo" onClick={() => this.redirection()}/>}
            <Switch>
              <Route path='/user/step1' render={this.registrationForm} />
              <Route path='/user/step2' render={this.registrationWellnessInterests} />
              <Route path='/user/step3' render={this.registrationFitnessGoals} />
              <Route path='/user/step4' render={this.registrationLegalUpdatesUserType} />
              <Route path='/user/reset/:id/:timestamp/:token' component={ResetPasswordModal} />
              <Route component={LoginPageV2} />
            </Switch>
            <BottomWrapper fullwidth={!loginPage}>
              <div className="left">
                <div className="text" onClick={() => window.open('https://woliba.io/terms-of-use', '_blank')}>{t("Terms Of Use")}</div>
                {/* <div className="text" onClick={() => history.push('/pricing')}>{t("Plans")}</div> */}
                <div className="text" onClick={() => window.open('https://woliba.io/contact', '_blank' )}>{t("Contact Us")}</div>
              </div>
              <div className="copyRight">
                <div className="text"><i className="far fa-copyright" />{moment().format('YYYY')}</div>&nbsp;
                <div className="dark">Training Amigo LLC. {t("All Rights Reserved")}</div>
              </div>
            </BottomWrapper>
          </Formwrapper>
        </Wrapper>
        <ToastContainer closeButton={<StyledButton> x </StyledButton>} />
      </Layout>
    );
  }
}

LoginV2.propTypes = {
  history: PropTypes.object.isRequired,
  authenticatedUser: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  getCompanyId: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  getSpouseDependentInfo: PropTypes.func,
  userType: PropTypes.number,
  isSpouseDependent: PropTypes.number,
  isSpouse: PropTypes.number,
  isDependent: PropTypes.number,
  getSpouseDependentAtCompanyLogin: PropTypes.func,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  selectedOption: PropTypes.string,
  location: PropTypes.object,
  authenticatedUserEmailToken: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  companyId: state.register.companyId,
  userType: state.register.user_type,
  isSpouseDependent: state.register.isSpouseDependent,
  isSpouse: state.register.isSpouse,
  isDependent: state.register.isDependent,
  locationDetails: state.register.locationDetails,
  departmentDetails: state.register.departmentDetails,
  selectedOption: state.register.selectedOption
});

const mapDispatchToProps = (dispatch) => ({
  authenticatedUser: () => dispatch(authenticatedUser()),
  getCompanyId: () => dispatch(getCompanyId()),
  getSpouseDependentInfo: () => dispatch(getSpouseDependentInfo()),
  getSpouseDependentAtCompanyLogin: () => dispatch(getSpouseDependentAtCompanyLogin()),
  authenticatedUserEmailToken: (token) => dispatch(authenticatedUserEmailToken(token))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginV2)));
