/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import moment from 'moment';

import { Layout as Wrapper, ContactUsSection, FooterText, ContentWrapper, Title, TitleContainer, StyledButton } from './styles';

import { ResetPasswordModal, LoginPage, RegistrationForm, RegistrationWellnessInterests, RegistrationFitnessGoals, RegistrationGeneralHealth, Layout, LocationDepartment } from '../../components';
import RegistrationLegalUpdates from '../../components/RegistrationLegalUpdates';

import { authenticatedUser, getCompanyId, getSpouseDependentInfo, getSpouseDependentAtCompanyLogin, authenticatedUserEmailToken } from '../../redux/actions';
import { checkPermission } from '../../utils/methods';
import {isUndefined} from 'lodash';
import Waiting from '../../components/Waiting';
import {LOGO_URL, COMPANY_NAME} from '../../utils/config';
class Login extends Component {

  checkResetPasswordLink(resetPasswordUrl) {
    if(resetPasswordUrl[resetPasswordUrl.length - 1] === '') {
      return resetPasswordUrl[2] !== 'reset' || resetPasswordUrl[resetPasswordUrl.length - 2] !== 'brief' ? false : true;
    }
    else {
      return resetPasswordUrl[2] !== 'reset' || resetPasswordUrl[resetPasswordUrl.length - 1] !== 'brief' ? false : true;
    }
  }

  componentDidMount() {
    const { history, location, token, authenticatedUser, getCompanyId, companyId, getSpouseDependentInfo, getSpouseDependentAtCompanyLogin, authenticatedUserEmailToken } = this.props;
    location && location.search ? authenticatedUserEmailToken(location.search.split('=')[1]) : null;
    if(checkPermission()) {
      if(!token) authenticatedUser();
      if(!companyId) getCompanyId();
      getSpouseDependentInfo();
      getSpouseDependentAtCompanyLogin();
      const authToken = localStorage.getItem('AUTH_TOKEN');
      const { pathname } = window.location;
      let resetPasswordUrl = pathname.split('/');
      if(companyId < 0) {
        if(!this.checkResetPasswordLink(resetPasswordUrl)) {
          history.push('/user');
        }
      }
      if(token !== '' || authToken) {
        history.push('/portal');
      }
    }
    else {
      alert("Please enable your cookies and localstorage");
      history.push('/');
    }
  }

  registrationLegalUpdates = () =>{
    const { history } = this.props;
    return(<RegistrationLegalUpdates  history={history} />)
  }
  
  registrationForm = () => {
    const { history, companyId, isSpouseDependent, isSpouse, isDependent, selectedOption } = this.props;
    return(<RegistrationForm companyId={companyId} history={history} isSpouseDependent={isSpouseDependent} isSpouse={isSpouse} isDependent={isDependent} selectedUserType={selectedOption} />)
  }

  registrationWellnessInterests = () => {
    const { history, companyId} = this.props;
    return(<RegistrationWellnessInterests companyId={companyId} history={history} />)
  }

  registrationFitnessGoals = () => {
    const { history, companyId} = this.props;
    return(<RegistrationFitnessGoals companyId={companyId} history={history}/>)
  }

  registrationGeneralHealth = () => {
    const { history, companyId} = this.props;
    return(<RegistrationGeneralHealth companyId={companyId} history={history} />)
  }

  registrationLegalUpdatesUserType = () => {
    const { history, userType} = this.props;
    return(<RegistrationLegalUpdates history={history} userType={userType}/>)
  }

  locationDepartment = () => {
    const { history, userType} = this.props;
    return(<LocationDepartment  history={history} userType={userType} />)
  }

  redirection = () =>{
    const { history } = this.props;
    history.push("/")
  }

  render() {
    const { departmentDetails, locationDetails } = this.props;
    if(isUndefined(locationDetails) || isUndefined(departmentDetails)) {
      return <Waiting />
    }
    return (
      <Layout>
        <Wrapper>
          <ContentWrapper>
            <TitleContainer height={LOGO_URL ? 1 : 0}>
              {LOGO_URL?
                <img
                  src={"/public/images/Logo/woliba_trans.png"}
                  alt="woliba-logo"
                  onClick={()=>this.redirection()}/>
                :
                <img
                  src="/public/images/Logo/training-amigo-logo-stacked.png"
                  alt="training-amigo-logo"
                  onClick={()=>this.redirection()}/>}
              <Title>{`It's Work Life Balance`}</Title>
            </TitleContainer>
            <Switch>
              <Route path='/user/step1' render={this.registrationForm} />
              <Route path='/user/step2' render={this.registrationWellnessInterests} />
              <Route path='/user/step3' render={this.registrationFitnessGoals} />
              <Route path='/user/step4' render={this.registrationLegalUpdatesUserType} />

              <Route path='/user/reset/:id/:timestamp/:token' component={ResetPasswordModal} />
              <Route component={LoginPage}/>
            </Switch>
            <ContactUsSection>
              <span>contact us to get started</span>
              <div>Get in touch with us!</div>
              {COMPANY_NAME === 'Training Amigo' ?  <a href='mailto:sales@trainingamigo.com'>sales@trainingamigo.com</a> : <a href='mailto:sales@woliba.io'>sales@woliba.io</a>}
            </ContactUsSection>
            <FooterText>
              Copyrights <i className="far fa-copyright"/> {`TrainingAmigo.com ${moment().format('YYYY')}. All rights reserved`}
            </FooterText>
          </ContentWrapper>
        </Wrapper>
        <ToastContainer closeButton={<StyledButton> x </StyledButton>} />
      </Layout>
    );
  }
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
  authenticatedUser: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  getCompanyId: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  getSpouseDependentInfo: PropTypes.func,
  userType: PropTypes.number,
  isSpouseDependent: PropTypes.number,
  isSpouse: PropTypes.number,
  isDependent: PropTypes.number,
  getSpouseDependentAtCompanyLogin: PropTypes.func,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  selectedOption: PropTypes.string,
  location: PropTypes.object,
  authenticatedUserEmailToken: PropTypes.func
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  companyId: state.register.companyId,
  userType: state.register.user_type,
  isSpouseDependent: state.register.isSpouseDependent,
  isSpouse: state.register.isSpouse,
  isDependent: state.register.isDependent,
  locationDetails: state.register.locationDetails,
  departmentDetails: state.register.departmentDetails,
  selectedOption: state.register.selectedOption
});

const mapDispatchToProps = (dispatch) => ({
  authenticatedUser: () => dispatch(authenticatedUser()),
  getCompanyId: () => dispatch(getCompanyId()),
  getSpouseDependentInfo: () => dispatch(getSpouseDependentInfo()),
  getSpouseDependentAtCompanyLogin: () => dispatch(getSpouseDependentAtCompanyLogin()),
  authenticatedUserEmailToken: (token) => dispatch(authenticatedUserEmailToken(token))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
